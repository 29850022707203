* {
  box-sizing: border-box;
}

body {
  font-family: "Karla", sans-serif;
  margin: 0;
}
main {
  padding: 36px;
}

.header {
  display: flex;
  align-items: center;
  height: 65px;
  background: linear-gradient(90deg, #672280 1.18%, #a626d3 100%);
  color: white;
  padding: 20px;
}

.header-image {
  height: 100%;
  margin-right: 6px;
}

.header-title {
  font-size: 1.25rem;
  margin-right: auto;
}

.header-project {
  font-size: 0.75rem;
  font-weight: 500;
}

.form {
  display: grid;
  grid-template: 40px 40px / 1fr 1fr;
  gap: 17px;
}

.form-input {
  font-family: "Karla", sans-serif;
  border-radius: 5px;
  border: 1px solid #d5d4d8;
  text-indent: 5px;
}

.form-button {
  grid-column: 1 / -1;
  font-family: "Karla", sans-serif;
  border-radius: 5px;
  background: linear-gradient(90.41deg, #711f8d 1.14%, #a818da 100%);
  color: white;
  border: none;
  cursor: pointer;
}

.meme-image {
  max-width: 100%;
}
